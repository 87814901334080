<template>
  <div class="wrap upFile" style="background-color: #fff;">
    <van-form @submit="onSubmit">
      <van-field name="uploader">
        <template #input>
          <van-uploader v-model="fileList" multiple :deletable="false" :max-count="fileList.length" />
        </template>
      </van-field>
    </van-form>
  </div>
</template>
<script>
import {upFileShow,getImgToken } from '@/server'
  export default {
  data() {
    return {
      // fileList: this.$route.query.fileList
      openId:this.$store.state.openId,
      contractNum:this.$route.query.contractNum,
      wxid:this.$route.query.wxid,
      fileList: [],
      fee_url: this.$route.query.feeUrl || '',
      imgToken:''
    };
  },
  mounted(){
    // sessionStorage.setItem('key', 0);
    // let that = this;
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    //   if(that.$route.path == '/modifyDebitCardDetail'){
    //     window.history.pushState('forward', null, '/modifyDebitCardDetail');
    //   }
    //   if(that.$route.path == '/earlySettlementDetail'){
    //     window.history.pushState('forward', null, '/earlySettlementDetail');
    //   }
    //   if(that.$route.path == '/corporateRepaymentDetail'){
    //     window.history.pushState('forward', null, '/corporateRepaymentDetail');
    //   }
    // }, false);
  }, 
  created(){
    //获取token
    getImgToken({openid:this.openId}).then(res=>{
      if(res.wx_code=='0'){
        this.imgToken=res.img_token;
        localStorage.setItem("token", res.img_token);
        if(this.fee_url){
          let fileurlList = this.fee_url && this.fee_url.split(',') || []
          fileurlList.map(item => {
            this.fileList = [...this.fileList,{url:item+'?Authorization='+this.imgToken}];
          });
          
        }else{
          this.showImg();
        }
      }
    })
  },
  methods: {
    //附件展示回显
    showImg(){
      const params = {
        wxid:this.wxid
      }
      upFileShow(params).then(res=>{//wx_code:0-存储成功,其他为错误 wx_code:1[没有读取到图片数据]"
        if(res.wx_code=='0'){
          res.imgurl.map(item => {
            this.fileList = [...this.fileList,{url:item+'?Authorization='+this.imgToken}];
          });
        }else{
          this.fileList = [];
        }
      })
    },
    onSubmit(){}
  },
};
</script>
<style scoped>
.wrap{
    padding: 20px 0px;
    
  }
</style>
<style>
.wrap .van-field__control{
  color: #666666 !important;
}
/* #app{
  background: #fff !important;
} */
.wrap .van-uploader__preview{
  margin-bottom: 20px;
  margin-left: 1.5%;
  margin-right: 1.5%;
  width: 30%;
}
.wrap .van-uploader__preview-image {
  width: 100%;
  height: 110px;
  border-radius: 8px;
}
.wrap .van-uploader__upload{
  margin-bottom: 10px;;
  width: 30%;
  height: 110px;
  border-radius: 8px;
  margin-left: 1.5%;
  /* margin-left: 2.5%; */
}
.wrap .van-uploader{
  width: 100%;
}
.upFile .van-cell:after{
  border: 0;
}
</style>